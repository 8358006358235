import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const TextFieldGroup = ({
    name,
    placeholder,
    value,
    label,
    error,
    info,
    type,
    onChange,
    disabled,
    id,
    required,
    maxLength
}) => {
    return (
        <div className='form-group '>
            <label className='float-left' htmlFor={id}>{label}</label>
            <input
                type={type}
                className={classnames('form-control', {
                    'is-invalid': error
                })}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                required={required}
                maxLength={maxLength}
                autoComplete='off'
                id={id}
            />
            {info && <small className='form-text text-muted text-left'>{info}</small>}
            {error && <div className='invalid-feedback text-left'>{error}</div>}
        </div>
    )
}

TextFieldGroup.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    info: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    maxLength: PropTypes.number
}

TextFieldGroup.defaultProps = {
    type: 'text'
}

export default TextFieldGroup
