import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../Layouts/Button'
import { addCategoryInMedia } from '../../actions/mediaAction'
import classNames from 'classnames'

const MainContainer = styled.div`
    h3 {
        text-align: center;
    }

    .category {
        cursor: pointer;
        transition: all 0.13s ease;
        height: 70px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${props => props.border_color} !important;
        border-top: 0px !important;

        h4 {
            font-size: 14px;
        }
    }

    .category.active {
        color: ${props => props.content_font_color};
        background-color: ${props => props.content_color};

        .fa-ellipsis-v {
            color: ${props => props.content_font_color} !important;   
        }

        .small-dot {
            background-color: ${props => props.content_font_color};
        }
    }

    .small-dot {
        position: relative;
        width: 5px;
        height: 5px;
        background-color: ${props => props.site_font_color};
        border-radius: 50%;
        right: 6px;
        bottom: 2px;
    }

    .not-found {
        font-size: 16px;
        font-weight: 600;
    }

    .btn-assign {
        position: sticky !important;
        bottom: -14px;
        margin-bottom: -15px; 
        width: calc(100% + 30px);
        margin-left: -16px;
    }
`

const SelectCategoryDiv = styled.div`
    inset: 0 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 18px;
`

const MediaManagerPopup = (props) => {
    const { card_header_border_color, content_color, content_font_color, site_font_color } = props.auth.appSettings
    const { category, filter } = props.media
    const [searchQuery, setSearchQuery] = useState('')
    const [activeCategory, setActiveCategory] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleCategory = async () => {
        setIsLoading(true)
        props.setCategoryPopupLoading(true)
        const data = {
            mediaId: props.selectedIds,
            categoryId: activeCategory,
            activeCategoryId: filter.activeCategory
        }
        await props.addCategoryInMedia(data)
        setIsLoading(false)
        props.setCategoryPopupLoading(false)
        props.setShowCategoryPopup(false)
        props.setSelectedIds([])
        props.setNewCategoryId('')
    }

    useEffect(() => {
        setActiveCategory(props.newCategoryId)
    }, [props.newCategoryId])

    const handleActiveCategory = (categoryId) => {
        if (activeCategory === categoryId) {
            setActiveCategory('')
        } else {
            setActiveCategory(categoryId)
        }
    }

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value)
    }

    // Filter the category Array
    const filteredCategory = category.filter(item =>
        !['all media'].includes(item.slug.toLowerCase()) &&
        item.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
    )

    const handleShowAddCategory = () => {
        props.handleAddOrUpdateCategory('add_category')
        props.setShowCategoryPopup(false)
        props.setIsOpenCategory(true)
        props.setIsMoveCategory(true)
    }

    return (
        <MainContainer
            content_color={content_color}
            content_font_color={content_font_color}
            border_color={card_header_border_color}
            site_font_color={site_font_color}
            className='container'
        >
            <div className='row'>
                <div className='col col-12 col-lg-12'>
                    <div className={classNames('row', { 'ml-lg-3': props.isPopup })}>
                        <div className='col-12 d-flex justify-content-between align-items-center pb-2 px-0'>
                            <input
                                className='form-control mr-2'
                                placeholder="Search category"
                                onChange={handleInputChange}
                                disabled={isLoading}
                            />
                            <Button
                                style={{ paddingTop: '4px', paddingBottom: '4px' }}
                                onClick={handleShowAddCategory}
                                loading={isLoading}
                            >
                                <i className="fas fa-plus"></i>&nbsp;
                            </Button>
                        </div>
                        {filteredCategory.length === 0 ?
                            <h5 className='text-center'>No category added</h5>
                            :
                            filteredCategory.map((category) => (
                                (category.is_deleted !== true && !['all media'].includes(category.slug)) &&
                                <div
                                    className={classNames('category col-12 p-0',
                                        {
                                            'active': category._id === activeCategory,
                                            'disabled': isLoading
                                        }
                                    )}
                                    key={category._id}
                                >
                                    <SelectCategoryDiv
                                        onClick={() => {
                                            handleActiveCategory(category._id)
                                        }}
                                        className='w-100 h-100'
                                    >
                                        <h4 className='m-0 fw-bold'>{category.name}</h4>
                                        {props.renderPhotoVideoCounts(category)}
                                    </SelectCategoryDiv>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            {filteredCategory.length > 0 &&
                <div
                    className='modal-header btn-assign p-0 justify-content-center'
                    style={{ borderBottom: 'none' }}
                >
                    <Button
                        loading={activeCategory === '' || isLoading} onClick={handleCategory}
                        type='button'
                    >
                        {isLoading && <span
                            className='spinner-border spinner-border-sm mr-2'>
                        </span>}
                        Assign Media
                    </Button>
                </div>
            }
        </MainContainer>
    )
}

MediaManagerPopup.propTypes = {
    auth: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    addCategoryInMedia: PropTypes.func.isRequired,
    selectedIds: PropTypes.array,
    handleAddOrUpdateCategory: PropTypes.func,
    activeCategory: PropTypes.string,
    setShowCategoryPopup: PropTypes.func,
    newCategoryId: PropTypes.string,
    setIsOpenCategory: PropTypes.func,
    renderPhotoVideoCounts: PropTypes.func,
    isPopup: PropTypes.bool,
    activeCategoryId: PropTypes.string,
    setCategoryPopupLoading: PropTypes.func
}

const mapStateToProps = state => ({
    auth: state.auth,
    media: state.media
})

export default connect(
    mapStateToProps, { addCategoryInMedia }
)(withRouter((MediaManagerPopup)))
