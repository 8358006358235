import {
    GALLERY_ADD_FILE,
    GALLERY_REMOVE_FILE,
    GALLERY_RESET,
    UPDATE_GALLERY_PROGRESS,
    UPDATE_GALLERY_INDEX,
    GALLERY_ADD_FILE_IN_PREVIEW,
    GALLERY_REMOVE_FILE_IN_PREVIEW,
    PREVIEW_GALLERY_RESET,
    SET_IS_PREVIEW_MODE_ENABLE,
    SWITCH_GALLERY
} from './types'

export const galleryAddFile = (file) => dispatch => {
    dispatch(galleryAddFileAction(file))
}

export const galleryRemoveFile = (index) => dispatch => {
    dispatch(galleryRemoveFileAction(index))
}

export const galleryReset = () => dispatch => {
    dispatch(galleryResetAction())
}

export const updateGalleryProgress = (object) => dispatch => {
    dispatch(updateGalleryProgressAction(object))
}

export const dragDropImage = (files) => dispatch => {
    dispatch(updateImage(files))
}

export const galleryAddFileInPreviewFile = (file) => dispatch => {
    dispatch(galleryAddFileInPreviewAction(file))
}

export const galleryRemoveFileInPreviewFile = (index) => dispatch => {
    dispatch(galleryRemoveFileInPreviewFileAction(index))
}

export const previewGalleryReset = () => dispatch => {
    dispatch(previewGalleryResetAction())
}

export const setIsPreviewModeEnable = () => dispatch => {
    dispatch(setIsPreviewModeEnableAction())
}

export const switchGallery = () => dispatch => {
    dispatch({
        type: SWITCH_GALLERY
    })
}

const updateImage = (files) => {
    return {
        type: UPDATE_GALLERY_INDEX,
        payload: files
    }
}

const galleryAddFileAction = (file) => {
    return {
        type: GALLERY_ADD_FILE,
        payload: file
    }
}

const galleryRemoveFileAction = (index) => {
    return {
        type: GALLERY_REMOVE_FILE,
        payload: index
    }
}

const galleryResetAction = () => {
    return {
        type: GALLERY_RESET
    }
}

const updateGalleryProgressAction = (object) => {
    return {
        type: UPDATE_GALLERY_PROGRESS,
        payload: object
    }
}

const galleryAddFileInPreviewAction = (file) => {
    return {
        type: GALLERY_ADD_FILE_IN_PREVIEW,
        payload: file
    }
}

const galleryRemoveFileInPreviewFileAction = (index) => {
    return {
        type: GALLERY_REMOVE_FILE_IN_PREVIEW,
        payload: index
    }
}

const previewGalleryResetAction = () => {
    return {
        type: PREVIEW_GALLERY_RESET
    }
}

const setIsPreviewModeEnableAction = () => {
    return {
        type: SET_IS_PREVIEW_MODE_ENABLE
    }
}
