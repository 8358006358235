import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FullScreenModelPopUpDialog from '../Layouts/FullScreenModelPopUpDialog'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import classNames from 'classnames'
import moment from 'moment'

const MediaDiv = styled.div`
    aspect-ratio: 1;
    width: 100%;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
`

const MediaWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    label {
        padding: 5px 5px 50px 50px;
        position: absolute;
        top: 2px; 
        right: 2px;
        z-index: 10;
    }

    input {
        position: absolute;
        top: 0px; 
        right: 6px;
        height: 24px;
        width: 24px;
        border-radius: 50% !important;
        border: 1px solid white;
        background: rgba(0, 0, 0, 0.5);
    }

    input:focus {
        border-color: transparent;
        box-shadow: none;
    }

    .meta {
        position: absolute;
        inset: 0 0 0 0;
        z-index: 9;
    }
    
    .meta span {
        position: absolute;
        background: rgba(0,0,0,0.5);
        color: white;
        padding: 0px 6px;
        border-radius: 6px;
        font-size: 12px;
    }

    .meta .size {
        right: 6px;
        bottom: 6px;
    }

    .meta .duration { 
        left: 6px;
        bottom: 6px;
    }

    .meta .date { 
        left: 6px;
        top: 6px;
    }

    span.video-handler {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        height: 30px;
        width: 30px;
        background-color: rgba(255,255,255,0.7);
        color: black;
    }

    span.processing-video-handler {
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        padding: 3px;
        border-radius: 4px;
        background-color: rgba(255,255,255,0.4);
        color: black;
    }

    span i.fa-play {
        position: relative;
        left: 1px;
    }
`

const MediaContainer = (props) => {

    const [openDialog, setOpenDialog] = useState(false)
    const { media, isSuperAdmin, selectedMedia, requestFrom, contentColor, contentFontColor } = props

    const convertSecondsToHours = (totalSeconds) => {
        let hours = Math.floor(totalSeconds / 3600)
        let minutes = Math.floor((totalSeconds % 3600) / 60)
        let seconds = totalSeconds % 60

        // Pad the numbers with leading zeros if necessary
        hours = String(hours).padStart(2, '0')
        minutes = String(minutes).padStart(2, '0')
        seconds = String(seconds).padStart(2, '0')

        return ((hours !== '00' ? (hours + ':') : '') + minutes + ':' + seconds)
    }

    const handleCheckboxChange = (e) => {
        props.mediaList(e)
    }

    const formatNumber = (value) => {
        return Number(value).toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        })
    }

    const convertBytes = (bytes) => {
        const divisor = media.type === 'photo' ? 1000 : 1024
        const sizeInMB = bytes / (divisor * divisor)

        if (sizeInMB < 1) {
            const sizeInKB = bytes / divisor
            return `${formatNumber(sizeInKB)} kB`
        } else if (sizeInMB >= 1000) {
            const sizeInGB = sizeInMB / divisor
            return `${formatNumber(sizeInGB)} GB`
        }
        return `${formatNumber(sizeInMB)} MB`
    }

    const formatDate = (dateString) => {
        let date = moment(dateString)
        let dateToShow = date.format('MMM D')
        let now = moment()
        if (date.isSame(now, 'day')) {
            dateToShow = 'Today'
        }
        else if (date.isSame(now.clone().subtract(1, 'day'), 'day')) {
            dateToShow = 'Yesterday'
        }
        return dateToShow
    }

    const handleMedia = (e) => {
        props.setMediaList(e.target.value)
    }

    const onChangeFunction = (requestFrom === 'category' || requestFrom === 'popup') ? handleMedia : handleCheckboxChange

    return (
        <MediaDiv className={classNames('grid-item', { 'pointer': !media.processing })}>
            <MediaWrapper contentColor={contentColor} contentFontColor={contentFontColor}>
                {media.processing
                    ? <>
                        <Image src={getCloudFrontAssetsUrl('images/processing.jpg')} alt='Media not found'></Image>
                        {media.type === 'video' &&
                            <span className='processing-video-handler d-flex align-items-center justify-content-center'>
                                <i className='fas fa-video'></i>
                            </span>}
                    </>
                    : <>
                        <input
                            id={media._id}
                            className='form-check-input checkbox'
                            checked={(selectedMedia && selectedMedia.includes(media._id))}
                            onChange={onChangeFunction}
                            type='checkbox'
                            value={media._id} />
                        <label htmlFor={media._id} className='d-flex justify-content-center align-items-center'>
                        </label>
                        <div className='meta' onClick={() => { setOpenDialog(true) }}>
                            {   // media size in kB/MB/GB
                                (media.size && isSuperAdmin) &&
                                <span className='size'>{convertBytes(media.size)}</span>
                            }
                            {   // video duration
                                media.type === 'video' &&
                                <span className='duration'>{convertSecondsToHours(media.video_duration)}</span>
                            }
                            {   // date when media was uploaded
                                media.createdAt &&
                                <span className='date'>{formatDate(media.createdAt)}</span>
                            }
                        </div>
                        {media.type === 'photo'
                            ? <Image src={media.small_thumb || media.thumb} alt='Media not found'></Image>
                            :
                            <>
                                <Image src={media.small_thumb || media.thumb} alt='Media not found'></Image>
                                <span className='video-handler d-flex align-items-center justify-content-center'>
                                    <i className='fas fa-play'></i>
                                </span>
                            </>
                        }
                    </>
                }
            </MediaWrapper>
            {openDialog && <FullScreenModelPopUpDialog
                url={media.path}
                handleClose={() => { setOpenDialog(false) }}
                type={media.type}
                reqFrom={props.requestFrom}
                offsetX={props.requestFrom === 'popup' && document.getElementById('media-selection-popup').offsetLeft}
                offsetY={props.requestFrom === 'popup' && document.getElementById('media-selection-popup').offsetTop}
            />}
        </MediaDiv>
    )
}

MediaContainer.propTypes = {
    media: PropTypes.object.isRequired,
    borderColor: PropTypes.string,
    isSuperAdmin: PropTypes.bool,
    selectedMedia: PropTypes.array,
    requestFrom: PropTypes.string,
    contentColor: PropTypes.string,
    contentFontColor: PropTypes.string,
    setMediaList: PropTypes.func,
    mediaList: PropTypes.func
}

export default MediaContainer
