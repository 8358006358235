import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../Layouts/Button'
import { connect } from 'react-redux'
import Media from './Media'
import { resetState } from '../../actions/mediaAction'

const Wrapper = styled.div`
    .modal-body {
        padding-bottom: 0px !important;
    }

    .modal-content{
        min-width: fit-content;
    }
`

const ModalStyle = styled.div`
    width: 100%;

    .media-modal-body {
        height: 96vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .modal {
        display: block;
    }

    .media-modal-content {
        color: ${props => props.siteFontColor};
        min-height: ${props => props.modalHeight} !important;
        width: 100vw;
        max-width: 1200px !important;
    }

    .modal-header, .modal-body {
        background-color: ${props => props.cardBackgroundColor};
    }

    .modal-body {
        max-height: ${props => props.modalBodyMaxHeight};
    }

    .close {
        color: ${props => props.siteFontColor};
        opacity: 1;
    }

    .close:hover {
        color: ${props => props.siteFontColor};
        opacity: 1;
        cursor: pointer;
    }
    .modal-dialog-scrollable .modal-body {
        overflow-x: hidden;
        padding: 16px 16px 15px 16px;
    }

    .select {
        background-color: ${props => props.cardBackgroundColor};
        z-index: 11;
    }

    @media (min-width: 576px) {
        .media-modal-dialog {
            width: 100vw;
            max-width: 1200px !important;
        }
    }
`

const MediaSelectionPopup = (props) => {

    const { card_background_color, site_font_color } = props.auth.appSettings
    const modalRef = useRef(null)
    const [selectedMedia, setSelectedMedia] = useState([])
    const [showSelectButton, setShowSelectButton] = useState(false)

    const handleClose = () => {
        props.onClose()
    }

    const handleMedia = (mediaId) => {
        setSelectedMedia(media => media.includes(mediaId) ? media.filter(item => item !== mediaId) : [...media, mediaId])
    }

    const handleClick = () => {
        props.onSelection(selectedMedia)
    }

    useEffect(() => {
        setSelectedMedia(media => [...props.prevSelectedMedia, ...media])
    }, [props.prevSelectedMedia])

    useEffect(() => {
        return () => {
            if (window.location.pathname === '/') {
                document.body.style.overflow = 'auto'
            }
            props.resetState()
        }
    }, [])

    return (
        <Wrapper>
            <ModalStyle
                siteFontColor={site_font_color}
                cardBackgroundColor={card_background_color}
            >
                <div className='modal fade show' role='dialog'>
                    <div id='media-selection-popup' className='media-modal-dialog modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='media-modal-content modal-content position-relative'>
                            <div className='modal-header align-items-center'>
                                <h5 className='modal-title'>{props.title ? props.title : 'Content Manager'}</h5>
                                <button className='close' onClick={handleClose} />
                            </div>
                            <div id='media-modal-body' className='media-modal-body modal-body p-0' ref={modalRef}>
                                <Media isPopup={true} onSelection={handleMedia} setShowSelectButton={setShowSelectButton} prevSelectedMedia={selectedMedia} />
                                {
                                    showSelectButton && <div className='select position-sticky bottom-0 d-flex justify-content-center'>
                                        <Button loading={selectedMedia.length === 0} onClick={handleClick} style={{ width: window.screen.width < 992 ? '92%' : 'auto' }}>Select</Button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ModalStyle>
        </Wrapper>
    )
}

MediaSelectionPopup.propTypes = {
    auth: PropTypes.object.isRequired,
    mediaState: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    onSelection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    loadMediaAction: PropTypes.func,
    loadMediaOnScrollAction: PropTypes.func,
    prevSelectedMedia: PropTypes.array
}

const mapStateToProps = state => ({
    auth: state.auth,
    mediaState: state.media
})

export default connect(
    mapStateToProps, {
    resetState
}
)((MediaSelectionPopup))
